import React from 'react'
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef,
} from 'react-spring'

function Logo() {

  const titleApi = useSpringRef()
  const title = useSpring({
    ref: titleApi,
    config: {
      mass: 0.25,
      tension: 120,
      friction: 14
    },
    from: { y: '-150px', },
    to: { y: '0', },
  })

  const subtitleApi = useSpringRef()
  const subtitle = useSpring({
    ref: subtitleApi,
    config: config.molasses,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  useChain([titleApi, subtitleApi], [0, 0.5])

  return (
    <header>
      <div id='logo'>
        <animated.h1 style={title}>Nicolaitious</animated.h1>
        <animated.h2 style={subtitle}>MULTIMeDIA DeSIGN<br />& ILLUSTRATION</animated.h2>
      </div>
    </header>
  )
}

export default function header() {
  return (
    < Logo />
  )
}