import React, { useEffect, useState, useRef } from 'react'
import Work from './Work'
import { ParallaxHover } from 'react-parallax-hover';
import {
  useTrail,
  config,
  animated,
  useSpringRef,
} from 'react-spring'

const LOCAL_STORAGE_KEY = 'nicolaitious.works'

export default function Works() {

  const [works, setWorks] = useState([]);
  const WorkNameRef = useRef()

  // useEffect(() => {
  //   const storedWorks = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
  //   if (storedWorks) setWorks(storedWorks)
  // }, [])

  // useEffect(() => {
  //   localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(works))
  // }, [works])

  useEffect(() => {
    getWorks()
  }, [])

  const getWorks = async (e) => {
    let result = await fetch(
      './api/works.php', {
      method: "get",
      dataType: "json",
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }),
      data: {
        tipo: 'Illustration'
      },
    })
    result = await result.json();
    if (result) {
      console.log(result);
      setWorks(result);
    }
  }

  const [state, setState] = useState(true);
  const config = { mass: 5, tension: 2500, friction: 200 };
  const trail = useTrail(works.length, {
    config,
    opacity: state ? 1 : 0,
    scale: state ? 1 : 0,
    from: { opacity: 0, scale: 0.5 }
  });

  return (
    <div id='works'>
      {trail.map(({ ...rest }, index) => (
        //<ParallaxHover width={420} height={300} scale={1} borderRadius={5} shine={0} key={works[index]._id}>
        <animated.div
          key={works[index]}
          className="work"
          onClick={() => setState(state => !state)}
          style={{
            ...rest, backgroundImage: "url(./imgs/" + works[index].nombreImagen + ")"
          }}
        >
          <Work work={works[index]} />
        </animated.div>
        //</ParallaxHover>
      ))
      }
      {/* < WorkCard name={name} description={description} imageName={imageName} /> */}
    </div >
  )
}
