import React from 'react'
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef,
} from 'react-spring'

export default function Work({ work }) {
  return (
    <h1> {work.name}</h1>
  )
}
